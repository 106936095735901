import { Navigation } from "../_models/index";

export const navigation: Navigation[] = [
  {
    id: "cms_dashboards",
    title: "Tổng quan",
    translate: "NAV.DASHBOARDS",
    type: "item",
    icon: "home",
    url: "/cms/dashboard",
    exactMatch: true,
  },
  {
    id: "cms_meeting",
    title: "Cuộc họp",
    translate: "NAV.MEETING",
    type: "group",
    icon: "user",
    url: "/cms/meeting",
    exactMatch: true,
    children: [
      {
        id: "cms_meeting_register",
        title: "Đăng ký lịch họp",
        translate: "NAV.MEETING_LIST.REGISTER",
        type: "item",
        url: "/cms/meeting/register",
        exactMatch: true
      },
      {
        id: "cms_meeting_meetnew",
        title: "Cuộc họp chờ phê duyệt",
        translate: "NAV.MEETING_LIST.MEET_NEW",
        type: "item",
        url: "/cms/meeting/meetingnew",
        exactMatch: true
      },
      {
        id: "cms_meeting_meetwaiting",
        title: "Cuộc họp chờ duyệt",
        translate: "NAV.MEETING_LIST.MEET_WAITING",
        type: "item",
        url: "/cms/meeting/meetingwaiting",
        exactMatch: true
      },
      {
        id: "cms_meeting_meetupcomming",
        title: "Cuộc họp sắp diễn ra",
        translate: "NAV.MEETING_LIST.MEET_UPCOMMING",
        type: "item",
        url: "/cms/meeting/meetingupcomming",
        exactMatch: true
      },
      {
        id: "cms_meeting_meethappening",
        title: "Cuộc họp đang diễn ra",
        translate: "NAV.MEETING_LIST.MEET_HAPPENING",
        type: "item",
        url: "/cms/meeting/meetinghappening",
        exactMatch: true
      },
      {
        id: "cms_meeting_meetclosed",
        title: "Cuộc họp đã kết thúc",
        translate: "NAV.MEETING_LIST.MEET_CLOSED",
        type: "item",
        url: "/cms/meeting/meetingclosed",
        exactMatch: true
      },
      {
        id: "cms_meeting_meetcancel",
        title: "Cuộc họp bị hủy",
        translate: "NAV.MEETING_LIST.MEET_CANCEL",
        type: "item",
        url: "/cms/meeting/meetingcancel",
        exactMatch: true
      }
    ]
  },
  {
    id: "cms_extension",
    title: "Tiện ích",
    translate: "NAV.EXTENSION",
    type: "group",
    icon: "list",
    url: "/cms/extension",
    exactMatch: true,
    children: [
      {
        id: "cms_extension_searchmeeting",
        title: "Tra cứu lịch theo phòng họp",
        translate: "NAV.EXTENSION_LIST.SEARCH_MEETING",
        type: "item",
        url: "/cms/extension/searchmeeting",
        exactMatch: true
      },
      {
        id: "cms_extension_taskmanager",
        title: "Giao việc",
        translate: "NAV.EXTENSION_LIST.WORKING",
        type: "item",
        url: "/cms/extension/taskmanager",
        exactMatch: true
      },
      {
        id: "cms_extension_document",
        title: "Tài liệu HDSD",
        translate: "NAV.EXTENSION_LIST.DOCUMENT",
        type: "item",
        url: "/cms/extension/document",
        exactMatch: true
      },
      // haohv
      {
        id: "cms_extension_readrecord",
        title: "Gỡ băng cuộc họp",
        translate: "NAV.EXTENSION_LIST.READ_RECORD",
        type: "item",
        icon: "home",
        url: "/cms/extension/readrecord",
        exactMatch: true,
      },
    ],
  },
  {
    id: "cms_workcalendar",
    title: "Lịch công tác",
    translate: "NAV.WORKCALENDAR",
    type: "group",
    icon: "home",
    url: "/cms/workcalendar",
    exactMatch: true,
    children: [
      {
        id: "cms_workcalendar_scheduleperson",
        title: "Lịch cá nhân",
        translate: "NAV.WORKCALENDAR_LIST.SCHEDULE_PERSON",
        type: "item",
        url: "/cms/workcalendar/scheduleperson",
        exactMatch: true
      },
      {
        id: "cms_workcalendar_schedulecompany",
        title: "Lịch cơ quan",
        translate: "NAV.WORKCALENDAR_LIST.SCHEDULE_COMPANY",
        type: "item",
        url: "/cms/workcalendar/schedulecompany",
        exactMatch: true
      },
      {
        id: "cms_workcalendar_scheduleleader",
        title: "Lịch lãnh đạo",
        translate: "NAV.WORKCALENDAR_LIST.SCHEDULE_LEADER",
        type: "item",
        url: "/cms/workcalendar/scheduleleader",
        exactMatch: true
      },
    ]
  },
  {
    id: "cms_report",
    title: "Báo cáo",
    translate: "NAV.REPORT",
    type: "group",
    icon: "list",
    url: "/cms/report",
    exactMatch: true,
    children: [
      {
        id: "cms_report_meeting",
        title: "Báo cáo cuộc họp",
        translate: "NAV.REPORT_LIST.REPORT_MEETING",
        type: "item",
        url: "/cms/report/report-meeting",
        exactMatch: true
      },
      {
        id: "cms_report_assign",
        title: "Báo cáo giao việc",
        translate: "NAV.REPORT_LIST.REPORT_ASSIGN",
        type: "item",
        url: "/cms/report/report-assign",
        exactMatch: true
      },
      // {
      //   id: "cms_report_meetingstatistics",
      //   title: "Thống kê lịch họp",
      //   translate: "NAV.REPORT_LIST.MEET_STATISTICS",
      //   type: "item",
      //   url: "/cms/report/meetingstatistics",
      //   exactMatch: true
      // },
      {
        id: "cms_report_message",
        title: "Quản lý nhắc họp",
        translate: "NAV.REPORT_LIST.MESSAGE",
        type: "item",
        icon: "home",
        url: "/cms/report/message",
        exactMatch: true,
      },
    ]
  },
  {
    id: "cms_masterdata",
    title: "Danh mục",
    translate: "NAV.MASTER_DATA",
    type: "group",
    icon: "list",
    url: "/cms/masterdata",
    exactMatch: true,
    children: [
      {
        id: "cms_masterdata_organization",
        title: "Đơn vị/Phòng ban",
        translate: "NAV.MASTER_DATA_LIST.ORGANIZATION",
        type: "item",
        url: "/cms/masterdata/organization",
        exactMatch: true
      },
      {
        id: "cms_masterdata_title",
        title: "Chức vụ",
        translate: "NAV.MASTER_DATA_LIST.TITLE",
        type: "item",
        url: "/cms/masterdata/title",
        exactMatch: true
      },
      {
        id: "cms_masterdata_meetroom",
        title: "Phòng họp",
        translate: "NAV.MASTER_DATA_LIST.MEET_ROOM",
        type: "item",
        url: "/cms/masterdata/meetroom",
        exactMatch: true
      },
      {
        id: "cms_masterdata_tabletype",
        title: "Loại hình bàn họp",
        translate: "NAV.MASTER_DATA_LIST.TABLE_TYPE",
        type: "item",
        url: "/cms/masterdata/tabletype",
        exactMatch: true
      },
      {
        id: "cms_masterdata_meetgroup",
        title: "Nhóm họp",
        translate: "NAV.MASTER_DATA_LIST.MEET_GROUP",
        type: "item",
        url: "/cms/masterdata/meetgroup",
        exactMatch: true
      },
      {
        id: "cms_masterdata_meettype",
        title: "Loại hình cuộc họp",
        translate: "NAV.MASTER_DATA_LIST.MEET_TYPE",
        type: "item",
        url: "/cms/masterdata/meettype",
        exactMatch: true
      },
      // {
      //   id: "cms_masterdata_notificationtemplate",
      //   title: "Mẫu thông báo",
      //   translate: "NAV.MASTER_DATA_LIST.NOTIFICATION_TEMPLATE",
      //   type: "item",
      //   url: "/cms/masterdata/notificationtemplate",
      //   exactMatch: true
      // },
      {
        id: "cms_masterdata_notificationtemplate",
        title: "Mẫu thông báo",
        translate: "NAV.MASTER_DATA_LIST.NOTIFICATION_TEMPLATE",
        type: "item",
        url: "/cms/masterdata/notificationtemplate",
        exactMatch: true
      },

      {
        id: "cms_masterdata_province",
        title: "Danh mục Tỉnh/Thành phố",
        translate: "NAV.MASTER_DATA_LIST.PROVINCE",
        type: "item",
        url: "/cms/masterdata/province",
        exactMatch: true
      },
      {
        id: "cms_masterdata_district",
        title: "Danh mục Quận/Huyện/Thị xã",
        translate: "NAV.MASTER_DATA_LIST.DISTRICT",
        type: "item",
        url: "/cms/masterdata/district",
        exactMatch: true
      },
      {
        id: "cms_masterdata_ward",
        title: "Danh mục tỉnh Xã/Phường/Thị Trấn",
        translate: "NAV.MASTER_DATA_LIST.WARD",
        type: "item",
        url: "/cms/masterdata/ward",
        exactMatch: true
      },
      {
        id: "cms_masterdata_wordtemplate",
        title: "Mẫu file xuất word",
        translate: "NAV.MASTER_DATA_LIST.WORD_TEMPLATE",
        type: "item",
        url: "/cms/masterdata/wordtemplate",
        exactMatch: true
      }
    ],
  },
  {
    id: "cms_authen",
    title: "Quản trị người dùng",
    translate: "NAV.AUTHEN",
    type: "group",
    icon: "user",
    url: "/cms/authen",
    exactMatch: true,
    children: [
      {
        id: "cms_authen_groupuser",
        title: "Nhóm quyền người dùng",
        translate: "NAV.AUTHEN_LIST.GROUP_USER",
        type: "item",
        url: "/cms/authen/groupuser",
        exactMatch: true
      },
      {
        id: "cms_authen_user",
        title: "Người dùng hệ thống",
        translate: "NAV.AUTHEN_LIST.USER",
        type: "item",
        url: "/cms/authen/user",
        exactMatch: true
      },
      // {
      //   id: "cms_authen_function",
      //   title: "Chức năng",
      //   translate: "NAV.AUTHEN_LIST.FUNCTION",
      //   type: "item",
      //   url: "/cms/authen/function",
      //   exactMatch: true
      // },
      // {
      //   id: "cms_authen_action",
      //   title: "Hành động",
      //   translate: "NAV.AUTHEN_LIST.ACTION",
      //   type: "item",
      //   url: "/cms/authen/action",
      //   exactMatch: true
      // },
      //hoan
      // {
      //   id: "cms_authen_message",
      //   title: "Quản lý thông báo",
      //   translate: "NAV.AUTHEN_LIST.MESSAGE",
      //   type: "item",
      //   icon: "home",
      //   url: "/cms/authen/message",
      //   exactMatch: true,
      // },
      {
        id: "cms_authen_loginlogging",
        title: "Lịch sử đăng nhập",
        translate: "NAV.AUTHEN_LIST.LOGGING_LOGIN",
        type: "item",
        url: "/cms/authen/loginlogging",
        exactMatch: true
      },
      // {
      //   id: "cms_authen_actionlogging",
      //   title: "Lịch sử thao tác hành động",
      //   translate: "NAV.AUTHEN_LIST.LOGGING_ACTION",
      //   type: "item",
      //   url: "/cms/authen/actionlogging",
      //   exactMatch: true
      // },
      {
        id: "cms_authen_actionlogging",
        title: "Lịch sử thao tác hành động",
        translate: "NAV.AUTHEN_LIST.LOGGING_ACTION",
        type: "item",
        url: "/cms/authen/actionlogging",
        exactMatch: true
      },
      {
        id: "cms_authen_apilogging",
        title: "Lịch sử thao tác API",
        translate: "NAV.AUTHEN_LIST.LOGGING_API",
        type: "item",
        url: "/cms/authen/apilogging",
        exactMatch: true
      },
      {
        id: "cms_authen_config",
        title: "Cấu hình hệ thống",
        translate: "NAV.AUTHEN_LIST.CONFIG",
        type: "item",
        url: "/cms/authen/config",
        exactMatch: true
      },
    ]
  }
];

