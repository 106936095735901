export class MeetRoom {
  id?: string;
  name?: string;
  room_position?: string;
  org_id?: string;
  org_name?: string;
  is_hall_room?: Boolean;
  orders?: number;
  status?: number;
  status_id?: boolean;
  table_type_id?: string;
  table_type_name?: string;
  vertical_number?: number;
  vertical_2_number?: number;
  vertical_table_number?: number;
  horizontal_number?: number;
  horizontal_2_number?: number;
  horizontal_table_number?: number;
  image_end_column_number?: number;
  image_end_row_number?: number;
  table_sort_rule?: string;
  numbering_number_rule?: string;
  table_sort_rule2?: Array<string>;
  numbering_number_rule2?: Array<string>;
  description?: string;
  image_links?: string;
  is_top?: Boolean;
  is_bottom?: Boolean;
  is_left?: Boolean;
  is_right?: Boolean;
  list_items?: Array<any>;
};
