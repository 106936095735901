export class TaskManager {
  id?: string;
  meeting_id?: string;
  content_id?: string;
  name?: string;
  nameStatus?: string;
  priority_id?: number;
  status?: number;
  deadline_status?: number;
  status_name?: string;
  status_2?: string;
  user_leader_id?: string;
  user_follow_id?: string;
  start_date?: string;
  deadline?: string;
  address?: string;
  description?: string;
  files?: string;
  comment?: string;
  user_created?: string;
  date_created?: string;
  directive_file?: string;
  user_explanation_id?: string;
  user_explanation_name?: string;
  date_explanation?: string;
  reason_explanation?: string;
  is_hide?: Boolean;
  list_items?: Array<TaskUser>;
  list_comments?: Array<any>;
  list_files?: Array<TaskManagerFile>;
}

export class TaskUser {
  id?: string;
  task_id?: string;
  user_id?: string;
  org_id?: string;
  is_org?: Boolean;
  full_name?: string;
  title_name?: string;
  org_name?: string;
}

export class TaskManagerFile {
  id?: string;
  task_id?: string;
  file_name?: string;
  links?: string;
}

export class TaskPeriodic {
  id?: string;
  name?: string;
  period_time?: number;
  period_id?: number;
  total_period?: number;
  next_date?: string;
}
